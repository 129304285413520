let mixin = {
  props: {
    openInDialog: {
      type: Boolean,
      default: false
    }
  }
}

export default mixin
