<template>
  <div>
    <el-form label-suffix=":" label-width="120px" size="mini">
      <el-row :gutter="24" type="flex">
        <el-col :span="12">
          <label>负责人</label>
          <el-form-item label="姓名">
          </el-form-item>
          <el-form-item label="电话">

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <label>安装车辆</label>
          <el-form-item label="车辆列表">
            <div class="btn-box">
              <i class="el-icon-plus"></i>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" type="flex">
        <el-col :span="12">
          <label>安装人员</label>
          <el-form-item label="选择人员">
            <el-checkbox>发送短信</el-checkbox>
          </el-form-item>
          <el-form-item label="安装时间">

          </el-form-item>
          <el-form-item label="备注">

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <label>地址信息</label>
          <el-form-item label="选择地址">

          </el-form-item>
          <el-form-item label="安装联系人">

          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" justify="center">
        <el-button v-ripple class="save" type="primary">保存</el-button>
        <el-button v-ripple class="cancel">取消</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [
    mixin
  ],
  props: {

  },
  data() {
    return {
      pobj: {
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.el-form {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 12px;
  .el-row--flex {
    align-items: stretch;
  }
  .el-row:not(:last-child) {
    margin-bottom: 24px;
  }
  .el-col {
    & > label {
      display: block;
      margin-top: 8px;
      margin-bottom: 18px;
      padding: 10px 12px;
      background-color: #F4F4F8;
      padding-left: 12px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.btn-box {
  border: 1px #DDD dashed;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #999;
  .icon, [class^=el-icon] {
    font-size: 24px;
  }
  &:hover {
    cursor: pointer;
    color: var(--color-c);
    border-color: var(--color-c);
  }
}
.el-button.save, .el-button.cancel {
  min-width: 80px;
}
</style>
